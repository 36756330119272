import { InvoiceTracking, Volume } from 'src/services/InvoiceTracking/invoice-tracking.types';

import { Container } from './volume-info.styles';
import Divider from 'src/components/atoms/Divider/divider.component';
import OrderStatus from 'src/components/molecules/OrderStatus/order-status.component';
import {
  defaultStatusList,
  failStatusList,
  takeoutStatusList
} from 'src/components/molecules/OrderStatus/order-status.list';
import Products from '../Products/products.component';
import TrackHeader from 'src/components/molecules/TrackHeader/track-header.component';
import useInvoiceUtils from 'src/hooks/useInvoiceUtils';

interface Props {
  volume: Volume;
  number: number;
  status: InvoiceTracking['status_name'];
  isTrackingAvailable: boolean;
  invoiceType: InvoiceTracking['type'];
}

const VolumeInfo: React.FC<Props> = ({
  status,
  volume,
  number,
  isTrackingAvailable,
  invoiceType
}) => {
  const { isCanceled, hasFailed } = useInvoiceUtils();
  const isTakeout = invoiceType === 'TAKEOUT';
  const notFailedStatusList = isTakeout ? takeoutStatusList : defaultStatusList;
  const statusList = hasFailed ? failStatusList : notFailedStatusList;
  return (
    <Container>
      {isTrackingAvailable && (
        <>
          <TrackHeader number={number} />
          {!isCanceled && (
            <>
              <Divider />
              <OrderStatus status={status} statusList={statusList} type={invoiceType} />
            </>
          )}
          <Divider />
        </>
      )}
      <Products items={volume.items} />
    </Container>
  );
};

export default VolumeInfo;
