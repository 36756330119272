const dict = {
  DELIVERY: {
    CREATED: 0,
    DISPATCHED: 1,
    HANDLING: 1,
    IN_TRANSIT: 1,
    COLLECTED: 1,
    START_DELIVERY: 2,
    SUCCESSFUL: 3,
    FAILED: 3,
    CANCEL: 3
  },
  TAKEOUT: {
    CREATED: 0,
    PENDING: 1,
    DISPATCHED: 2,
    SUCCESSFUL: 3
  }
};

export const statusToStatusCircleIndex = (status: string, type: string) => dict[type][status] || 0;
